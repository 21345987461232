.timePickerOutTime:before {
  content: "OutTime: ";
  margin-right: 1.3em;
  color: #9d9d9d;
}

.timePickerInTime:before {
  content: "InTime:  ";
  margin-right: 1.3em;
  color: #9d9d9d;
}

.uploadPhoto:before {
  content: "Photo ";
  margin-right: 1.3em;
  color: #9d9d9d;
}

.uploadDrivingLicence:before {
  content: "Licence ";
  margin-right: 1.3em;
  color: #9d9d9d;
}

.adharCard:before {
  content: "Adharcard ";
  margin-right: 1.3em;
  color: #9d9d9d;
}

.pucDoc:before {
  content: "PUC ";
  margin-right: 1.3em;
  color: #9d9d9d;
}

.insurance:before {
  content: "Insurance ";
  margin-right: 1.3em;
  color: #9d9d9d;
}

.uploadLogo:before {
  content: "Logo  ";
  margin-right: 1.3em;
  color: #9d9d9d;
}

.uploadTemplate:before {
  content: "Template  ";
  margin-right: 1.3em;
  color: #9d9d9d;
}
