@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&display=swap");

@font-face {
  font-family: "Open Sans";
  src: url("./OpenSans-Bold.woff2") format("woff2"),
    url("./OpenSans-Bold.woff") format("woff"),
    url("./OpenSans-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Open Sans";
  src: url("./OpenSans-SemiBold.woff2") format("woff2"),
    url("./OpenSans-SemiBold.woff") format("woff"),
    url("./OpenSans-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Open Sans";
  src: url("./OpenSans-Medium.woff2") format("woff2"),
    url("./OpenSans-Medium.woff") format("woff"),
    url("./OpenSans-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Open Sans";
  src: url("./OpenSans-Regular.woff2") format("woff2"),
    url("./OpenSans-Regular.woff") format("woff"),
    url("./OpenSans-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Open Sans";
  src: url("./OpenSans-Light.woff2") format("woff2"),
    url("./OpenSans-Light.woff") format("woff"),
    url("./OpenSans-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
