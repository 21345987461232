/*Open Sans
Light 300
Regular 400
Medium 500
SemiBold 600
Bold 700*/
* {
  margin: 0;
  padding: 0;
  outline: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  list-style: none;
  vertical-align: top;
  text-decoration: none;
}

html,
body {
  font-size: 14px;
  line-height: 20px;
  font-family: "Open Sans", sans-serif;
  height: 100%;
  color: #064b6f;
  font-weight: 400;
}

strong {
  font-weight: 700;
}

a {
  color: #e4b51c;
  text-decoration: none;
  cursor: pointer;
}

img {
  max-width: 100%;
  height: auto;
}

/*Header
-------------------------------*/
#header {
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: #eceff5 1px solid;
}

.sideToggle {
  background: #f8f9fb;
  height: 36px;
  width: 36px;
  display: inline-block;
  border-radius: 50%;
}

.searchBar {
  display: inline-block;
  padding-left: 20px;
  width: 100%;
}

.searchBar input {
  border: 0;
  background: url(../images/search.svg) 0 center no-repeat;
  height: 30px;
  padding-left: 30px;
  background-size: 20px auto;
}

.searchInput {
  width: 75%;
}

@media only screen and (max-width: 1420px) {
  .searchInput {
    width: 50%;
  }
}

.userbar {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.userImg {
  height: 36px;
  width: 36px;
  border-radius: 50%;
  overflow: hidden;
  display: inline-block;
}

.userImg img {
  width: 100%;
  height: auto;
}

.userName {
  display: inline-block;
  padding-left: 10px;
  font-weight: 600;
  line-height: 18px;
}

.userName span {
  display: block;
  font-size: 12px;
  font-weight: 400;
}

.login {
  background: #ec9b22 url(../images/signupbg.gif) 0 0 no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loginBox {
  background: #ffffff;
  padding: 20px 40px;
  border-radius: 10px;
  width: 100%;
  max-width: 450px;
  text-align: center;
}

.loginBox .logowrap {
  margin: 20px 0;
  width: 100%;
  display: inline-block;
}

.loginBox form label {
  display: block;
  padding-bottom: 20px;
}

.loginBox form label span {
  display: block;
  padding-bottom: 5px;
}

.loginBox a img {
  width: 140px;
}

.login-text {
  font-size: 32px;
  font-weight: bold;
  padding-top: 20px;
  padding-bottom: 20px;
}

.welcome-text {
  font-size: 14px;
  color: black;
  padding-bottom: 30px;
}

.text-right {
  text-align: right;
}

.signUpmessage {
  padding-top: 10px;
}

/*Container
-------------------------------*/
#wrapper {
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
}

#sidebar {
  width: 300px;
  background: #f8f9fb;
  border-right: #eceff5 1px solid;
  position: relative;
  padding: 20px 12px;
  min-height: 100vh;
}

#sidebar:before {
  background: #ec9b22;
  width: 60px;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  content: "";
}

.logo {
  position: relative;
  padding-left: 67px;
  display: block;
  margin-bottom: 40px;
}

.logo img {
  width: 160px;
  height: auto;
}

.logo:before {
  position: absolute;
  left: 0;
  top: 50%;
  background: url(../images/ico-logo.svg) 0 0 no-repeat;
  width: 30px;
  height: 30px;
  content: "";
  margin-top: -18px;
}

#content {
  width: calc(100% - 300px);
}

.content {
  padding: 20px;
}

.mainNav {
  margin: 0;
  padding: 0;
  position: relative;
  z-index: 2;
}

.mainNav li {
  padding-bottom: 8px;
}

.mainNav li a {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #939598;
  position: relative;
}

.mainNav li a.ddNav:after {
  border: solid #939598;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  position: absolute;
  right: 10px;
  top: 10px;
  content: "";
}

.mainNav li a.ddNav.active:after {
  transform: rotate(226deg);
  -webkit-transform: rotate(226deg);
}

.mainNav li a:hover,
.mainNav li a.active {
  color: #ec9b22;
  font-weight: 600;
}

.mainNav li a i {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  width: 36px;
  /* background: #ffffff; */
  border-radius: 5px;
  margin-right: 32px;
}

.mainNav li > ul {
  padding: 10px 0 0 90px;
  display: none;
}

.mainNav li > ul li a {
  color: #797a7d;
}

.infoBox {
  border: #eceff5 1px solid;
  border-radius: 10px;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.infoLeft {
  font-size: 20px;
  line-height: 34px;
  font-weight: 700;
  color: #064b6f;
}

.infoLeft span {
  display: block;
  color: #939598;
  font-size: 14px;
  line-height: 24px;
  font-weight: bold;
}

.boxOuter {
  padding-top: 20px;
}

.box {
  border: #eceff5 1px solid;
  border-radius: 10px;
  padding: 15px;
}

.box th {
  color: #939598;
  font-weight: 800;
  font-size: 16px;
}

.message_name {
  background: #f8f9fb;
  color: #939598;
  font-weight: 600;
  position: absolute;
  top: 14px;
  padding-left: 10px;
}

.message a {
  color: black;
  font-size: 13px;
}

.box td {
  vertical-align: middle;
}

.box tr {
  border-color: #eceff5;
}

.box td.blTxt {
  color: #0b51b7;
}

.box td.blTxt span {
  color: #5a5c61;
  font-size: 13px;
  display: block;
}

.box-head {
  margin: 0 -15px 10px;
  width: calc(100% + 30px);
  padding: 0 15px 10px;
  color: #064b6f;
  font-size: 17px;
  line-height: 24px;
  font-weight: 700;
  border-bottom: #eceff5 1px solid;
}

.st {
  background: #aa7330;
  width: 26px;
  line-height: 26px;
  text-align: center;
  color: #fff;
  font-weight: 700;
  margin: 0 5px;
  display: inline-block;
  border-radius: 50%;
}

.stt {
  background: #ec9b22;
}

.formBg {
  /* background: #f8f9fb;
  border-top: #eceff5 1px solid; */
  padding: 20px;
}

input[type="text"].form-control,
textarea.form-control {
  height: 50px;
  padding: 0 20px;
  border: #d1d2d4 1px solid;
  background: transparent;
  border-radius: 5px;
}

textarea.form-control {
  padding: 10px 20px;
  background: transparent;
  height: 140px;
}

.btn {
  background: #3a8396;
  border-radius: 5px;
  border: 0;
  height: 50px;
  line-height: 50px;
  padding: 0 30px;
  text-align: center;
  min-width: 120px;
  margin: 0 5px;
}

.btn:hover {
  background: #4799af;
}

input[type="text"]::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: rgba(6, 75, 111, 0.5);
}

input[type="text"]:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: rgba(6, 75, 111, 0.5);
}

input[type="text"]::placeholder,
textarea::placeholder {
  color: rgba(6, 75, 111, 0.5);
}

.profilePage {
  margin-top: 100px;
}

.profilePic {
  margin: -85px 0 15px;
  text-align: center;
}

.profilePicInner {
  display: inline-block;
  background: #fff;
  border: #eceff5 1px solid;
  position: relative;
  border-radius: 50%;
  height: 150px;
  width: 150px;
  padding: 10px;
}

.profilePic img {
  width: 100%;
  height: auto;
  border-radius: 50%;
}

.editPhoto {
  position: absolute;
  right: 0;
  top: 0;
  width: 38px;
  height: 38px;
}

.profileList {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  background: #f2f2f2;
  padding: 30px;
}

.profileList li {
  padding: 7px;
  width: 50%;
  color: black;
}

.profileList li span {
  font-weight: 600;
  display: inline-block;
  padding-right: 10px;
  min-width: 100px;
  color: #064b6f;
}

.profileCont {
  padding-bottom: 30px;
  text-align: center;
}

.profileName {
  color: #064b6f;
  font-size: 18px;
  line-height: 24px;
  padding-bottom: 5px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profileName span {
  display: inline-block;
  margin-left: 5px;
}

.profilePhone {
  padding-bottom: 5px;
  color: #939598;
}

.profileEmail {
  color: #064b6f;
  font-weight: 600;
}

.pageTitle {
  font-size: 26px;
  line-height: 27px;
  font-weight: 900;
  padding: 20px;
  background: #fff;
  margin: -20px -20px 20px;
  width: calc(100% + 40px);
  border-bottom: #eceff5 1px solid;
}

/*Footer
-------------------------------*/
.custCheck {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.custCheck input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.custCheck .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #fff;
  border: #d1d2d4 1px solid;
  border-radius: 5px;
}

.custCheck input:checked ~ .checkmark {
  background-color: #cb8a33;
}

.custCheck .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.custCheck input:checked ~ .checkmark:after {
  display: block;
}

.custCheck .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.custRadio {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.custRadio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.custRadio .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #fff;
  border-radius: 50%;
  border: #d1d2d4 1px solid;
}

.custRadio input:checked ~ .checkmark {
  background-color: #cb8a33;
}

.custRadio .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.custRadio input:checked ~ .checkmark:after {
  display: block;
}

.custRadio .checkmark:after {
  top: 6px;
  left: 6px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: white;
}

select.form-control {
  height: 50px;
  background: none;
  padding: 0 17px;
  appearance: auto;
}

.pointer {
  cursor: pointer;
}

@media only screen and (max-width: 992px) {
  .infoBox {
    margin: 5px 0;
  }

  .profileList li {
    width: 100%;
  }
}

.tableForReport {
  border-collapse: collapse;
  width: 100%;
  font-family: Arial, sans-serif;
}

.thForReport,
.tdForReport {
  text-align: left;
  padding: 8px;
  border: 1px solid #ddd;
}

.thForReport {
  background-color: #ec9b22;
  color: white;
}

.trForReport:nth-child(even) {
  background-color: #f2f2f2;
}

.card-link {
  color: inherit !important;
  text-decoration: none !important;
}

.f-size {
  font-size: 24px;
}

.headerTitle {
  margin: 1px -20px 20px !important;
  border-top: #eceff5 1px solid;
}

.client {
  position: absolute;
  left: 420px;
}

.email-control {
  height: 49px;
}

.btnBackg {
  background-color: #3a8396;
  color: white;
  padding: 0px 5px 0px;
  border-radius: 4px;
}

.sp_1 {
  height: 50.2px;
  padding: 12px 20px;
  background: #f2f2f2;
}

.sp_2 {
  height: 50.2px;
  padding: 12px 20px;
}

.float-right {
  float: right;
}

.small_box {
  font-size: 12px;
}

.text-justify {
  text-align: justify;
}

.profile_header {
  margin: 0px 0px 24px 68px !important;
}

input.session-date {
  display: inline-block;
  position: relative;
}

input.session-time {
  display: inline-block;
  position: relative;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  display: block;
  top: 0;
  left: 0;
  background: #0000;
  position: absolute;
  transform: scale(12);
}

input[type="month"]::-webkit-calendar-picker-indicator {
  display: block;
  top: 0;
  left: 0;
  background: #0000;
  position: absolute;
  transform: scale(12);
}

input[type="time"]::-webkit-calendar-picker-indicator {
  display: block;
  top: 0;
  left: 0;
  background: #0000;
  position: absolute;
  transform: scale(12);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"]::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: rgba(6, 75, 111, 0.5);
}

input[type="number"]:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: rgba(6, 75, 111, 0.5);
}

input[type="number"]::placeholder,
textarea::placeholder {
  color: rgba(6, 75, 111, 0.5);
}

input[type="number"].form-control,
textarea.form-control {
  height: 50px;
  padding: 0 20px;
  border: #d1d2d4 1px solid;
  background: transparent;
  border-radius: 5px;
}

textarea.form-control {
  padding: 10px 20px;
  background: transparent;
  height: 140px;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - (0.5rem * 2));
}

.modal-content {
  margin: auto;
}

.card {
  border-bottom: #eceff5 1px solid !important;
  border: none;
}

.card-header {
  background: white;
  border-bottom: none;
  color: black;
}

.logout {
  background: #ec9b22 !important;
}

.logout a {
  color: white !important;
}

label {
  position: relative;
  /* margin-bottom: 1rem; */
}

.top-placeholder {
  position: absolute;
  pointer-events: none;
  left: 1.5rem;
  top: -0.75rem;
  font-size: 0.8rem;
  color: #333;
  background-color: #ffffff;
  padding-left: 0.2rem;
  padding-right: 0.2rem;
  font-weight: 700;
}

/* label input:focus ~ span,
label input:not(:placeholder-shown) ~ span {
} */

input[type="date"].form-control {
  height: 50px;
  padding: 0 20px;
  border: #d1d2d4 1px solid;
  background: transparent;
  border-radius: 5px;
}

input[type="month"].form-control {
  height: 50px;
  padding: 0 20px;
  border: #d1d2d4 1px solid;
  background: transparent;
  border-radius: 5px;
}

input[type="time"].form-control {
  height: 50px;
  padding: 0 20px;
  border: #d1d2d4 1px solid;
  background: transparent;
  border-radius: 5px;
}

.btn--small {
  background: #3a8396;
  color: white;
  border-radius: 5px;
  border: 0;
  height: 36px;
  padding: 6px 12px;
  text-align: center;
  min-width: 80px;
  max-width: 80px;
  margin: 0 5px;
}

.no-message {
  font-size: 13px;
  color: gray;
  font-weight: 600;
}

.sign-up-placeholder {
  position: absolute;
  pointer-events: none;
  left: 0.9rem;
  top: -0.75rem;
  font-size: 0.9rem;
  color: #333;
  padding: 0px 10px;
  background-color: #ffffff;
  font-weight: 700;
}

.empty-form {
  border-color: red !important;
}

.rel {
  position: relative;
}

.popover {
  position: absolute;
  /* top: 40px; */
  /* right: 100px; */
  /* left: 600px; */
  width: 500px;
  height: auto;
  /* padding: 0px 10px 0px 10px; */
  padding: 10px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.popover-title {
  font-weight: bold;
  background-color: yellow;
}

.map_title {
  font-size: 10px;
  margin-left: 140px;
  font-weight: bold;
  background-color: #fd7e14;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-radius: 10%;
}

.modal-overlay {
  position: absolute;
  top: 100px;
  /* left: 100px; */
  background-color: #fff;
  display: flex;
  align-items: center;
  z-index: 1000;
  border-radius: 0.375rem;
  box-shadow: 0 2px 4px 2px rgba(0, 0, 0, 0.2);
  width: 1000px;
  height: 500px;
  /* width: 100%; */
  flex-direction: column;
  overflow-x: auto;
  text-align: center;
}

.modal-title {
  font-size: 20px;
  font-weight: bold;
  margin: 10px;
}

.modal-overlay::-webkit-scrollbar {
  width: 5px;
}

.modal-overlay::-webkit-scrollbar-track {
  background: transparent;
}

.modal-overlay::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

.modal-overlay::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.4);
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px white inset !important;
  -webkit-text-fill-color: inherit !important;
  background-color: transparent !important;
}

/* Use For Chrome Auto Suggest back-Ground Color remove from input*/

textarea:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px white inset !important;
  -webkit-text-fill-color: inherit !important;
  background-color: transparent !important;
}

/* Use For Chrome Auto Suggest back-Ground Color remove from textarea*/

.form-control {
  font-size: 0.9rem !important;
}

/* Use for placeholder text size*/

.react-tabs__tab--selected {
  background: #ea9c34;
  border-color: #ea9c34;
  border-radius: 5px 5px 0 0;
  color: #fff;
  font-weight: bold;
}

.react-tabs__tab-list {
  border-bottom: 1px solid #ea9c34;
  margin: 0 0 10px;
  padding: 0;
}

.react-tabs__tab:hover {
  color: #ea9c34;
  font-weight: 600;
}

.react-tabs__tab--selected:hover {
  color: #fff;
  font-weight: bold;
}

.sup_heading {
  margin: 20px 0px 20px 0px;
  width: calc(100% + 30px);
  padding: 20px 0px 20px 4px;
  color: black;
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
  border-bottom: #eceff5 1px solid;
  border-top: #eceff5 1px solid;
}

.Approved_Size {
  height: 25px;
}

.Location_img {
  height: 25px;
}

.badgeCol {
  background-color: #ec9b22;
}

.badgeFontCol {
  color: #228b22;
  cursor: default;
  font-size: 14px;
  font-weight: 600;
}

/* **********************PlanRenew********************************************** */
.container-md {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.box-Plan {
  border: 1px solid #f9dcd3;
  border-radius: 5px;
  box-shadow: 0 7px 13.5px 1.5px rgba(0, 0, 0, 0.05);
  float: left;
  margin: 0 15px;
  padding: 0 45px 55px;
  text-align: center;
  transition: all 0.3s;
  cursor: pointer;
}

.box-Plan.active,
.box-Plan:hover {
  color: #ffffff;
  background: #14212b;
  background-size: cover;
  position: relative;
  z-index: 1;
  transform: scale(1.05);
}

.typee {
  background-color: #ea9c34;
  border-radius: 30px;
  display: inline-block;
  margin-top: 40px;
  padding: 12px 60px;
  color: #ffffff;
}

.get-started {
  background: #ea9c34;
  color: #fff;
  margin: auto;
}

.box-Plan.active:before {
  background: url(../images/pricing-bg.1ccd4bc0a1199c0ef64f.jpg) no-repeat 50%;
  border-radius: 5px;
  content: "";
  height: 100%;
  left: 0;
  opacity: 0.2;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
}

.pricing-body ul {
  list-style: none;
  margin-bottom: 50px;
}

.get-started {
  border: 1px solid #f9dcd3;
  border-radius: 5px;
  color: #ea9c34;
  color: white;
  display: block;
  font-weight: 500;
}

.typee h5 {
  font-size: 1.3333333333rem;
  font-family: "PT Serif", serif;
  font-weight: 600;
  margin: 0;
  text-transform: uppercase;
}

.container-md ul > li + li {
  text-align: center;
  margin-top: 12px;
}

.container-md ul {
  padding: 0px !important;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
  cursor: not-allowed;
}

.tPaddings {
  padding: 3px;
}
